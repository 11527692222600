import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input, Radio, Space } from 'antd';
import { Popconfirm, Table } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import logo from './logo.svg';
import './App.css';
import TextArea from 'antd/es/input/TextArea';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

function App() {

  const [dataSource, setDataSource] = useState([
    {
      key: '0',
      name: 'King',
      relationship: 'Self',
      gender: 'F',
      dob: '12/12/1993',
      zipcode: '165451',
      county: 'LOS ANGELES',
      tobacco: '12/01/2001'
    },
    {
      key: '1',
      name: 'Edward',
      relationship: 'Spouse',
      gender: 'F',
      dob: '12/12/1993',
      zipcode: '165451',
      county: 'LOS ANGELES',
      tobacco: '12/01/2001' 
    },
  ]);

  const [value, setValue] = useState(1);
  const [productCheck, setProductCheck] = useState('');
  const [sub, setSub] = useState(false);

  const [count, setCount] = useState(2);

  const handleDelete = (key) => {
    if(dataSource.length > 1) {
      const newData = dataSource.filter((item) => item.key !== key);
      setDataSource(newData);
    }
  };

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const onChangeTwo = (e) => {
    console.log('radio checked', e.target.value);
    setProductCheck(e.target.value);
  };

  const onFinish = (values) => {
    console.log('Success:', values);
    setSub(true);
  };

  const onFinishTwo = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const defaultColumns = [
    {
      title: 'First Name',
      dataIndex: 'name',
      width: '30%',
      editable: true,
    },
    {
      title: 'Relationship',
      dataIndex: 'relationship',
      editable: true,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      editable: true,
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      editable: true,
    },
    {
      title: 'Zip Code',
      dataIndex: 'zipcode',
      editable: true,
    },
    {
      title: 'County',
      dataIndex: 'county'
    },
    {
      title: 'Tobacco',
      dataIndex: 'tobacco',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record) =>
        dataSource.length > 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData = {
      key: count,
      name: `John ${count}`,
      relationship: 'Partner',
      gender: `M`,
      dob: `11/12/1993`,
      zipcode: `19534`,
      county: `LOS ANGELES`,
      tobacco: ``,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const onChangeCheck = (e) => {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleContinue = () => {
    console.log('dataSource',dataSource);
  }

  return (
    <>
      {
        !sub && 
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 offset-md-3 col-md-6">
              <h1 className='border-dark border-top border-bottom py-2'>Where do you live ?</h1>
              <p>
                Please enter your Zip Code and select the Coverage Type of insurance you are interested in.
              </p>
              <h3 className='text-primary fs-4 mt-2 mb-5'>Primary Applicant Info</h3>
              <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  
                  label="Zip Code"
                  name="zipcode"
                  wrapperCol={{
                    span: 12,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter zip code!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Plan Type" name="plan">
                  <Radio.Group>
                    <Radio value="family-plan" className='cust-radio'>Individual & Family Plans</Radio>
                    <Radio value="child-plan" className='cust-radio'>Child Only Plans - Ages 0 - 18</Radio>
                    <Radio value="senior-plan" className='cust-radio'>Senior Plans - Ages 65+</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item>
                  <Button className='rounded-0' type="primary" htmlType="submit">
                    Continue
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      }

      {sub && 
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-12 offset-md-1 col-md-10'>
              <h2 className='border-dark border-top border-bottom py-2'>Who are you shopping for?</h2>
              <p>
                Enter the information requested below for the insured plan members to be included in this proposal.
              </p>
              <p>
                <small>NOTE: Items with a * are required</small>
              </p>

              <h3 className='text-primary fs-4 mt-2 mb-5'>Primary Applicant Info</h3>

              <Form
                name="basic"
                onFinish={onFinishTwo}
                autoComplete="off"
              >
                <Form.Item
                  label="First Name"
                  name="fname"
                  wrapperCol={{
                    span: 12,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter first name!',
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lname"
                  wrapperCol={{
                    span: 12,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter last name!',
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                  label="Phone"
                  name="phone"
                  wrapperCol={{
                    span: 12,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter phone!',
                    },
                  ]}
                >
                  <Input style={{marginLeft: 25}}/>
                </Form.Item>
                <Form.Item
                  label="State"
                  name="state"
                  wrapperCol={{
                    span: 12,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter state!',
                    },
                  ]}
                >
                  <Input style={{marginLeft: 34}}/>
                </Form.Item>
                <Form.Item
                  label="City"
                  name="city"
                  wrapperCol={{
                    span: 12,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter city!',
                    },
                  ]}
                >
                  <Input style={{marginLeft: 40}}/>
                </Form.Item>
                <Form.Item
                  label="Address"
                  name="address"
                  wrapperCol={{
                    span: 12,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter address!',
                    },
                  ]}
                >
                  <Input style={{marginLeft: 13}}/>
                </Form.Item>
                <Form.Item
                  label="Comment"
                  name="comment"
                  wrapperCol={{
                    span: 12,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter comment!',
                    },
                  ]}
                >
                  <TextArea style={{marginLeft: 2}}/>
                </Form.Item>

              </Form>

              
              <div className='mt-5'>
                <Button
                  onClick={handleAdd}
                  type="primary"
                  className='rounded-0'
                  style={{
                    marginBottom: 16,
                  }}
                >
                 + Add Decendent
                </Button>
                <Table
                  components={components}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                />
              </div>

              {/* NEW */}
              <h4 className='text-primary'>Product Type</h4>
              <Radio value="family-plan" className='cust-radio'>Short-Term Health</Radio>

              <Form.Item label="Payment Option:" >
                <Radio.Group onChange={onChangeTwo} value={productCheck}>
                  <Radio value="single-payment" className='cust-radio ps-4'>Single Payment</Radio>
                  <Radio value="monthly-payment" className='cust-radio'>Monthly Payment</Radio>
                </Radio.Group>
              </Form.Item>

              {
                productCheck && productCheck == 'single-payment' && 
                <div className='card p-3 mb-4 bg-light rounded-0'>
                  <p>
                    <small>Note: Some insurance companies only offer a "Monthly Payment" option. You may not receive all available plans by selecting a single payment option. </small>
                  </p>

                  <div className='d-flex flex-row'>
                    <Form.Item
                      label="Start Coverage On"
                      name="coverage"
                      wrapperCol={{
                        span: 10,
                      }}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                      label="Last Coverage On"
                      name="coverage"
                      wrapperCol={{
                        span: 10,
                      }}
                    >
                      <Input/>
                    </Form.Item>
                  </div>
                </div> 
              }
              {
                productCheck && productCheck == 'monthly-payment' && 
                <div className='card p-3 mb-4 bg-light rounded-0'>
                  <p>
                    <small>Note: Some insurance companies only offer a "Single Payment" option. You may not receive all available plans by selecting a monthly payment option. </small>
                  </p>

                  <div className='d-flex flex-row'>
                    <Form.Item
                      label="Start Coverage On"
                      name="coverage"
                      wrapperCol={{
                        span: 10,
                      }}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                      label="Coverage For Up To"
                      name="coverage"
                      wrapperCol={{
                        span: 10,
                      }}
                    >
                      <Input/>
                    </Form.Item>
                  </div>
                </div> 
              }

              

              <div className='bg-light p-3 d-flex flex-row justify-content-end align-items-center mb-4'>
                <Checkbox className='me-4' onChange={onChangeCheck}>Remember My Quote Information</Checkbox>
                <Button
                  className='rounded-0'
                  onClick={handleContinue}
                  type="primary"
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default App;
